import { template as template_225d3921379044169e1f81fcdc45b802 } from "@ember/template-compiler";
const FKText = template_225d3921379044169e1f81fcdc45b802(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
