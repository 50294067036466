import { template as template_34a9cdd0a42f4e5b9f399a3eb4f19adb } from "@ember/template-compiler";
import Component from "@glimmer/component";
export default class FKControlCustom extends Component {
    static controlType = "custom";
    static{
        template_34a9cdd0a42f4e5b9f399a3eb4f19adb(`
    <div class="form-kit__control-custom">
      {{yield}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
