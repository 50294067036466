import { template as template_1cd9e5be055e441e910c62e8ed222e05 } from "@ember/template-compiler";
const FKLabel = template_1cd9e5be055e441e910c62e8ed222e05(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
