import { template as template_0478caecbaf04eec9beb28de11a09d79 } from "@ember/template-compiler";
const WelcomeHeader = template_0478caecbaf04eec9beb28de11a09d79(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
